<template>
  <div class="mm-copy-product-link__container">
    <button
      class="btn"
      :class="{
        'btn-text': isCopied
      }"
      @click="onClick"
    >
      <template v-if="!isCopied">
        <SvgIcon :src="EIconPath.ActionLinkSvg" />
        {{ copyTextButton }}
      </template>
      <template v-else>
        <SvgIcon :src="EIconPath.IndicatorsCheckmarkSimpleSvg" />
        Ссылка скопирована
      </template>
    </button>
  </div>
</template>

<script lang="ts" setup>
import { EIconPath } from 'shared/enums/iconPath.enum';

const props = withDefaults(defineProps<{
  copyUrl: string,
  copyTextButton: string,
  isExternalLink: boolean
}>(), {
  copyTextButton: 'Копировать ссылку',
});

const isCopied = ref(false);

async function onClick(): Promise<void> {
  await navigator.clipboard.writeText(props.isExternalLink ? props.copyUrl : `${window.location.origin}${props.copyUrl}`);
  isCopied.value = true;
}
</script>

<style lang="scss" scoped>
@import 'styles/base/common/variables';

.mm-copy-product-link__container {
  button {
    font-weight: 500 !important;
    padding: 0;
    transition: all .15s;

    :deep(svg) {
      width: 24px;
      height: 24px;
      margin: 0 12px 0 0;

      path {
        transition: all .15s;
      }
    }

    &:not(.btn-text) {
      color: $text-dark-green;

      &:hover {
        color: $link;

        :deep(path) {
          fill: $link;
        }
      }
    }

    &.btn-text {
      &:hover {
        color: $link;

        :deep(path) {
          stroke: $link;
        }
      }

      :deep(path) {
        fill: none !important;
        stroke: $link;
      }
    }
  }
}
</style>
